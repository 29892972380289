import Chart from 'chart.js/auto'
import { data } from './data.js'

(async function() {
  new Chart(
    document.getElementById('airspeed'),
    {
      type: 'line',
      data: {
        labels: data.map(x => x.t),
        datasets: [
          {
            label: 'power',
            yAxisID: 'yAxisL2',
            data: data
              .map(row => ({
                x: row.t,
                y: row.rpm
              }))
          },
          {
            label: 'airspeed',
            yAxisID: 'yAxisL1',
            data: data
              .map(row => ({
                x: row.t,
                y: row.spd
              }))
          },
          {
            label: 'altitude',
            yAxisID: 'yAxisR',
            data: data
              .map(row => ({
                x: row.t,
                y: row.alt
              }))
          },
          {
            label: 'cht3',
            yAxisID: 'yAxisL3',
            data: data
              .map(row => ({
                x: row.t,
                y: row.cht
              }))
          },
        ]        
        
      },
     options: {
    scales: {
      yAxisL1: {
        position: 'left',
        display: 'true',
        text: 'airspeed'
      },
      yAxisL2: {
        position: 'left',
        display: 'true',
        text: 'altitude'
      },
      yAxisL3: {
        position: 'left',
        display: 'true',
        text: 'cht'
      },
      yAxisR: {
        // The axis for this scale is determined from the first letter of the id as `'y'`
        // It is recommended to specify `position` and / or `axis` explicitly.
        position: 'right',
        display: 'true',
        text: 'altitude'
      }
    }       
     }  
    }
  );
})();

